.App {
  position: relative;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
}
